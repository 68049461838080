@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.top {
  top: 20px;
}
.bottom {
  bottom: -0px;
}
.top1 {
  top: 0px;
}
.bottom1 {
  bottom: -0px;
}
.left {
  left: -266px;
}
.right {
  right: -266px;
}
.learn {
  position: absolute;
  width: 244px;
  height: max-content;
  background: $primary_white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000010;
  border: 1px solid $primary_grey;
  &__popup {
    @include flexColumnACenter;
    padding: 12px;
    &__card {
      width: 100%;
      min-height: 165px;
      background: $grey_button;
      border-radius: 6px;
      img {
        width: 100% !important;
        display: block !important;
        object-fit: cover;
        padding: 0 !important;
        opacity: 1 !important;
      }
    }
    &__content {
      width: 100%;
      padding: 12px 0 0 0;
      height: 100%;
      h4 {
        font-size: 12px;
        font-weight: 600;
        padding: 0 0 4px 0;
        color: $primary_blue;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          font-size: 12px;
          font-weight: 400;
          color: $primary_blue;
          list-style-type: none;
        }
      }
      a {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        text-decoration: none;
        color: #2ba471;
        font-size: 14px;
        font-weight: 400;
        padding: 16px 0 0 0;
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1610px) {
  .learn {
    width: 200px;
    &__popup {
      padding: 10px;
      &__card {
        width: 100%;
        // height: 135px;
      }
      &__content {
        h4 {
          font-size: 10px;
        }
        p {
          font-size: 10px;
        }
        a {
          font-size: 12px;
        }
      }
    }
  }
  .left {
    left: -222px;
  }
  .right {
    right: -222px;
  }
}
