@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.account {
  @include flexJCenter;
  background: $bg_light_grey;
  height: 100%;
  
  .cropper-modal{
    height: 450px;
    border-radius: 10px;
    opacity: 1;
    align-items: center;
    &__main {
      justify-content: center;
    }
  }

  &__container {
    @include flexJCenter;
    height: 100%;
    overflow: auto;
  }

  &__sections {
    width: 952px;
    padding: 12px 0 24px 0;
  }

  &__heading {
    padding: 0 0 8px 0;

    &__section {
      @include flexAcenter;
      position: relative;

      &__image {
        @include flexCenter;
        position: relative;
        border-radius: 50%;
        border: 1px solid #ededed;

        img {
          width: 60px;
          padding: 6px;
        }

        .pencil-icon {
          position: absolute;
          bottom: -2px;
          right: 8px;
          width: 14px;
          border-radius: 50%;
          background-color: white;
          box-shadow: 6px 6px 9px 0 rgb(210, 210, 210),
            -1px -1px 3px 0 rgb(201, 201, 201);
          padding: 0.4vw;
          cursor: pointer;
        }
      }

      &__edit {
        position: absolute;
        top: 0;
        width: calc(100% - 2vw);
        height: 24px;
        background: #00000080;
        padding: 16px;
        border-radius: 10px;
        z-index: 1000;
      }

      &__content {
        padding: 0 0 0 12px;

        h4 {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }

  &__section-main {
    @include flexJCenter;
    padding: 0 0;
  }

  &__section {
    @include flexbetween;
    width: 100%;

    &__left {
      width: 468px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__user {
        background: $primary_white;
        border-radius: 8px;
        border: 1px solid $primary_grey;

        &__heading {
          @include flexbetween;
          align-items: center;
          padding: 0 20px 0 20px;
          height: 52px;
          border-bottom: 1px solid $primary_grey;

          h3 {
            font-size: 18px;
            font-weight: 600;
          }

          button {
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            border-radius: 15px;
            outline: none;
            border: none;
            color: $primary_white;
            background: $primary_black;
            padding: 4px 8px;
            height: max-content;
          }
        }

        &__details {
          @include flexwrap;
          justify-content: space-between;
          padding: 0 20px 20px 20px;
        }

        &__input {
          width: 100%;
          @include flexcolumn;
          padding: 20px 0 0 0;

          label {
            font-size: 14px;
            color: $primary_blue;
            padding: 0 0 8px 0;
          }

          input {
            font-size: 14px;
            border: 1px solid $primary_grey;
            border-radius: 120px;
            padding: 6px 0 6px 16px;
            color: $font_grey;
            outline: none;
          }

          .phone {
            border: none;
            padding: 7px 1px 7px 17px;
          }
          .phone.active {
            border: 1px solid $primary_grey;
            padding: 6px 0 6px 16px;
          }

          input::placeholder {
            color: $font_grey;
          }

          &__input {
            @include flexrowbetween;
            align-items: center;

            input {
              width: calc(80% - 16px);
            }

            button {
              font-size: 12px;
              font-weight: 400;
              cursor: pointer;
              border-radius: 15px;
              outline: none;
              border: none;
              color: $primary_white;
              background: $primary_black;
              padding: 4px 8px;
            }
          }
        }
      }

      &__setting {
        background: $primary_white;
        border-radius: 8px;
        border: 1px solid $primary_grey;
        margin: 12px 0 0 0;

        &__heading {
          @include flexAcenter;
          justify-content: space-between;
          padding: 0 20px 0 20px;
          height: 52px;
          border-bottom: 1px solid $primary_grey;

          h3 {
            font-size: 18px;
            font-weight: 600;
          }
          button {
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            border-radius: 15px;
            outline: none;
            border: none;
            color: $primary_white;
            background: $primary_black;
            padding: 4px 8px;
            height: max-content;
          }
        }

        &__details {
          @include flexwrap;
          justify-content: space-between;
          padding: 0 20px 8px 20px;
          &__sec {
            width: 100%;
            padding: 10px 0;
            h5 {
              font-size: 14px;
              font-weight: 500;
              color: $primary_blue;
            }
            &__top {
              display: flex;
              padding: 12px 0 0 0;
              img {
                width: 14px;
                height: 14px;
              }
              p {
                padding: 0 0 0 8px;
                font-size: 12px;
                color: $font_grey;
              }
            }
            &__content {
              @include flexbetween;
              padding: 10px 0;
              &__left {
                width: 5%;
                .react-toggle-slider {
                  width: 40px;
                  height: 20px;
                }
                .react-toggle-slider .toggle-slider-knob {
                  width: 18px;
                  height: 18px;
                }
              }
              &__right {
                width: 85%;
                h6 {
                  font-size: 14px;
                  font-weight: 400;
                  color: $font_grey;
                }
                p {
                  font-size: 12px;
                  font-weight: 400;
                  color: $font_grey;
                }
              }
            }
          }
        }

        &__data {
          width: 100%;
          @include flexbetween;
          padding: 0 0 8px 0;

          h4 {
            @include flexAcenter;
            font-size: 14px;
            color: $font_grey;
            font-weight: 400;
            img {
              padding: 0 0 0 8px;
              width: 14px;
            }
          }

          h5 {
            font-size: 14px;
            color: $font_grey;
            font-weight: 400;
          }
        }
        &__data:last-child {
          padding: 0;
        }
      }
      &__bottom1 {
        display: none;
        width: 100%;
        justify-content: center;
        padding: 12px 0 0 0;
      }
      &__bottom {
        width: 100%;
        @include flexJCenter;
        padding: 12px 0 0 0;
        &__links {
          width: 45%;
          @include flexbetween;
          a {
            font-size: 12px;
            color: $font_grey;
            text-decoration: none;
          }
        }
      }
    }

    &__right {
      width: 468px;
      @include flexcolumn;

      &__subscription {
        background: $primary_white;
        border-radius: $font_xverysmall;
        border: 1px solid $primary_grey;
        @include flexcolumn;
        height: max-content;

        &__heading {
          @include flexbetween;
          align-items: center;
          padding: 0 20px;
          height: 52px;
          border-bottom: 1px solid $primary_grey;

          h3 {
            font-size: 18px;
            font-weight: 600;
          }

          button {
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            border-radius: 15px;
            outline: none;
            border: none;
            color: $primary_white;
            background: $primary_black;
            padding: 4px 8px;
          }
        }
        &__details {
          &__main {
            padding: 20px;
          }
          &__heading {
            &__content {
              @include flexbetween;
              h5 {
                font-size: 14px;
                font-weight: 400;
              }
              span {
                font-size: 12px;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          &__sections {
            &__plan {
              padding: 16px 0 8px 0;
              &__card {
                padding: 24px 12px;
                border-radius: 4px;
                &__heading {
                  @include flexbetween;
                  padding: 0 0 8px 0;
                  h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $primary_white;
                  }

                  span {
                    color: $primary_white;
                    background: #2ba471;
                    border: 1px solid $primary_white;
                    font-size: 12px;
                    padding: 3px 8px;
                    border-radius: 4px;
                  }

                  .border {
                    border: none;
                  }

                  .change {
                    background: white;
                    color: $primary_blue;
                  }

                  button {
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                    border-radius: 15px;
                    outline: none;
                    border: none;
                    color: $primary_white;
                    background: $grey_input;
                    padding: 4px 8px;
                  }
                }
                &__content {
                  &__sec {
                    @include flexbetween;
                    padding: 0 0 6px 0;
                    h5 {
                      font-size: 14px;
                      font-weight: 400;
                      color: $primary_white;
                    }
                    h6 {
                      font-size: 14px;
                      font-weight: 400;
                      color: $primary_white;
                    }
                  }
                  .line {
                    border-bottom: 1px solid white;
                    width: 100%;
                    margin: 6px 0 12px 0;
                  }
                }
              }
            }
            &__concurrent {
              padding: 0 0 8px 0;
              &__card {
                padding: 12px;
                border-radius: 4px;
                background: $bg_grey_backround;
                border: 1px solid $primary_grey;
                margin: 0 0 12px 0;
                &__heading {
                  @include flexbetween;
                  padding: 0 0 8px 0;
                  h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $primary_blue;
                  }
                }
                &__content {
                  &__sec {
                    @include flexbetween;
                    padding: 0 0 6px 0;
                    h5 {
                      font-size: 14px;
                      font-weight: 400;
                      color: $font_grey;
                    }
                    h6 {
                      font-size: 14px;
                      font-weight: 400;
                      color: $font_grey;
                    }
                  }
                  &__sec:nth-child(3) {
                    padding: 12px 0 0 0;
                    margin: 12px 0 0 0;
                    border-top: 1px solid $primary_grey;
                  }
                }
              }
            }
            &__privilage {
              padding: 8px 0;
              &__heading {
                @include flexbetween;
                padding: 16px 0;
                h3 {
                  font-size: 14px;
                  font-weight: 500;
                  color: $primary_blue;
                }
              }
              &__card {
                padding: 12px;
                border-radius: 4px;
                background: $bg_grey_backround;
                border: 1px solid $primary_grey;
                max-height: 25vh;
                overflow: scroll;
                &__content:last-child {
                  padding: 0 0 0 0;
                  border-bottom: none;
                }
                &__content {
                  width: 100%;
                  @include flexcolumn;
                  align-items: flex-start;
                  &__sec {
                    width: 100%;
                    padding: 0px 0;
                    @include flexrowacenter;
                    img {
                      align-self: flex-start;
                      width: 14px;
                      height: 14px;
                      padding: 6px 0;
                    }
                    h5 {
                      font-size: 14px;
                      font-weight: 400;
                      color: $font_grey;
                      padding: 0 0 0 8px;
                    }
                    ul{
                      margin: 4px 0px;
                      padding: 0 0 0 24px;
                    }
                  }
                  &__sec:nth-child(4) {
                    padding: 0 0 12px 0;
                    margin: 0 0 12px 0;
                    border-bottom: 1px solid $primary_grey;
                  }
                }
              }
            }
          }
        }
        &__gif {
          display: flex;
          justify-content: center;
          padding: 0 0 12px 0;
          img {
            width: 5vw;
          }
        }
      }

      &__plan {
        background: $primary_white;
        border-radius: $font_xverysmall;
        border: 1px solid $primary_grey;
        margin: $font_small 0 0 0;

        &__heading {
          @include flexAcenter;
          padding: 0 20px 0 20px;
          height: 52px;
          border-bottom: 1px solid $primary_grey;

          h3 {
            font-size: 18px;
            font-weight: 600;
          }
        }

        &__details {
          @include flexwrap;
          justify-content: space-between;
          padding: 0 20px 8px 20px;
          &__sec {
            width: 100%;
            border-bottom: 1px solid $primary_grey;
            padding: 12px 0;
          }
          &__sec:last-child {
            border: none;
          }
        }

        &__data {
          width: 100%;
          @include flexbetween;
          padding: 0 0 8px 0;

          &__left {
            @include flexrow;
          }

          h4 {
            @include flexAcenter;
            font-size: 14px;
            color: $font_grey;
            font-weight: 400;
          }

          &__icon {
            position: relative;
            img {
              padding: 0 0 0 8px;
              width: 14px;
            }
          }

          h5 {
            font-size: 14px;
            color: $font_grey;
            font-weight: 400;
          }
        }
        &__data:last-child {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .account {
    &__sections {
      width: calc(720px - 20px);
      padding: 12px 10px 24px 10px;
    }
    &__heading {
      &__section {
        &__image {
          img {
            width: 40px;
            padding: 4px;
          }
        }
        &__content {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
    &__section-main {
      padding: 16px 0 0 0;
    }
    &__section {
      &__left {
        width: 342px;
        &__user {
          &__heading {
            height: 36px;
            h3 {
              font-size: 14px;
            }
            button {
              font-size: 10px;
            }
          }
          &__details {
            padding: 0 12px 12px 12px;
          }
          &__input {
            padding: 12px 0 0 0;
            label {
              font-size: 10px;
            }
            input {
              width: 80%;
              font-size: 10px;
              padding: 5px 0 5px 12px;
            }
            &__input {
              button {
                font-size: 10px;
              }
            }
          }
        }
        &__plan {
          &__heading {
            height: 36px;
            h3 {
              font-size: 14px;
            }
          }
          &__details {
            padding: 0 12px 12px 12px;
          }
          &__data {
            h4 {
              font-size: 10px;
              img {
                width: 10px;
              }
            }
            h5 {
              font-size: 10px;
            }
          }
        }
        &__bottom {
          &__links {
            a {
              font-size: 10px;
            }
          }
        }
      }
      &__right {
        width: 342px;
        &__subscription {
          &__heading {
            h3 {
              font-size: 14px;
            }
            button {
              font-size: 10px;
            }
          }
          &__details {
            &__main {
              padding: 12px;
            }
            &__heading {
              &__content {
                h5 {
                  font-size: 12px;
                }
                span {
                  font-size: 10px;
                }
              }
            }
            &__sections {
              &__plan {
                &__card {
                  &__heading {
                    h3 {
                      font-size: 12px;
                    }
                    button {
                      font-size: 10px;
                    }
                  }
                  &__content {
                    &__sec {
                      h5 {
                        font-size: 12px;
                      }
                      h6 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
              &__concurrent {
                &__card {
                  &__heading {
                    h3 {
                      font-size: 12px;
                    }
                    button {
                      font-size: 10px;
                    }
                  }
                  &__content {
                    &__sec {
                      h5 {
                        font-size: 12px;
                      }
                      h6 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
              &__privilage {
                &__heading {
                  h3 {
                    font-size: 12px;
                  }
                }
                &__card {
                  &__content {
                    &__sec {
                      img {
                        width: 10px;
                        height: 10px;
                        padding: 0 6px 0 0;
                      }
                      h5 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .account {
    &__heading {
      &__section {
        &__image {
          img {
            width: 36px;
            padding: 4px;
          }
          .pencil-icon {
            right: 4px;
            width: 10px;
          }
        }
        &__content {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
    &__sections {
      width: 650px;
    }
    &__section {
      flex-direction: column;
      &__left {
        width: 100%;
        &__user {
          &__heading {
            height: 32px;
            padding: 0 12px;
            h3 {
              font-size: 14px;
            }
            button {
              font-size: 10px;
            }
          }
          &__details {
            padding: 0 12px 12px 12px;
          }
          &__input {
            padding: 12px 0 0 0;
            label {
              font-size: 12px;
              padding: 0 0 6px 0;
            }
            input {
              font-size: 12px;
              padding: 4px 0 4px 12px;
            }
            &__input {
              button {
                font-size: 10px;
              }
            }
          }
        }
        &__setting {
          &__heading {
            height: 32px;
            padding: 0 12px;
            h3 {
              font-size: 14px;
            }
            button {
              font-size: 10px;
            }
          }
          &__details {
            padding: 0 12px;
            &__sec {
              padding: 6px 0;
              h5 {
                font-size: 12px;
              }
              &__content {
                padding: 6px 0;
                &__right {
                  width: 90%;
                  h6 {
                    font-size: 12px;
                  }
                  p {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
        &__bottom {
          display: none;
        }
        &__bottom1 {
          display: flex;
        }
      }
      &__right {
        width: 100%;
        padding: 12px 0 0 0;
        &__subscription {
          &__heading {
            height: 32px;
            padding: 0 12px;
            h3 {
              font-size: 14px;
            }
            button {
              font-size: 10px;
            }
          }
          &__details {
            &__main {
              padding: 12px 12px 0 12px;
            }
            &__sections {
              &__plan {
                padding: 12px 6px;
                &__card {
                  padding: 16px 8px;
                  &__heading {
                    h3 {
                      font-size: 14px;
                    }
                    span {
                      font-size: 10px;
                    }
                  }
                  &__content {
                    &__sec {
                      h5 {
                        font-size: 12px;
                      }
                      h6 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
              &__concurrent {
                padding: 0 6px 12px 6px;
                &__card {
                  padding: 12px 6px;
                  &__heading {
                    h3 {
                      font-size: 14px;
                    }
                  }
                  &__content {
                    &__sec {
                      h5 {
                        font-size: 12px;
                      }
                      h6 {
                        font-size: 12px;
                      }
                    }
                    &__sec:nth-child(3) {
                      padding: 8px 0 0 0;
                      margin: 8px 0 0 0;
                    }
                  }
                }
              }
              &__privilage {
                &__heading {
                  padding: 6px 0 10px 0;
                }
                &__card {
                  padding: 6px 12px;
                  &__content {
                    &__sec {
                      padding: 0 0 8px 0;
                      img {
                        width: 10px;
                        height: 10px;
                        padding: 0 6px 0 0;
                      }
                      h5 {
                        font-size: 12px;
                      }
                    }
                    &__sec:nth-child(4) {
                      padding: 0 0 8px 0;
                      margin: 0 0 8px 0;
                    }
                  }
                }
              }
            }
          }
        }
        &__plan {
          &__heading {
            height: 36px;
            h3 {
              font-size: 14px;
            }
          }
          &__details {
            &__sec {
              padding: 6px 0;
            }
          }
          &__data {
            &__left {
              h4 {
                font-size: 12px;
              }
            }
            &__icon {
              img {
                width: 12px;
              }
            }
            h5 {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .account {
    &__sections {
      width: 94%;
    }
  }
}
