@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.feed-popover {
  width: 196px;
  .ant-popover-inner-content {
    font-size: 14px;
    text-align: center !important;
  }
}
.feed-popover.ch {
  width: 127px;
}

.feed {
  user-select: text;
  &__popup {
    @include flexCenter;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    z-index: 100;
    background: rgba(0, 0, 0, 0.7098039216);
    box-sizing: border-box;
    padding: 60px 12px 12px 12px;
    .showcase {
      overflow: hidden;
    }
    &__main {
      width: 100%;
      height: 100%;
      @include flexAcenter;
    }
    &__container {
      box-sizing: border-box;
      width: calc(100% - 45px);
      height: calc(100%);
      padding: 12px;
      @include flexrow;
      background: $primary_white;
      border-radius: $font_xverysmall;
    }
    &__section {
      width: 100%;
      &__heading {
        position: absolute;
        top: -12px;
        right: -57px;
        padding: 0 0 12px 0;
        &__container {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          padding: 0;
        }
        &__icons {
          width: auto;
          height: auto;
          @include flexaround;
          flex-direction: column;
          position: relative;
          background: $primary_grey;
          padding: 26px 12px;
          margin: 12px 0 0 0;
          border-radius: 0 8px 8px 0;
          border-top: 1px solid $primary_white;
          border-right: 1px solid $primary_white;
          border-bottom: 1px solid $primary_white;
          .tooltip-top {
            margin: 0 0 16px 0;
            img {
              cursor: pointer;
              width: 20px;
              height: 20px;
              opacity: 1;
            }
            .active-load {
              opacity: 0.6;
              cursor: not-allowed !important;
            }
            .fav {
              display: block;
            }
            .fav1 {
              display: none;
            }
            .disabled {
              opacity: 0.5;
            }
            .tooltip-show {
              display: none;
              right: -100px;
            }
            .small-icon {
              width: 18px;
              height: 18px;
            }
          }
          .tooltip-top:last-child {
            margin: 0;
          }
          .tooltip-top:hover {
            .tooltip-show {
              display: block;
            }
            // .fav {
            //   display: none;
            // }
            // .fav1 {
            //   display: block;
            // }
          }
        }
        &__close {
          width: 20px;
          height: 20px;
          background: $primary_grey;
          @include flexCenter;
          border-radius: 50%;
          cursor: pointer;
          img {
            width: 13px;
            height: 13px;
          }
        }
      }
      &__main {
        img {
          width: 100%;
          height: 75vh;
          object-fit: contain;
        }
      }
    }
  }
}
.custom-slider {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: calc(100% - 76px);
    position: relative;
  }
  &__main {
    width: calc(100% - 376px);
    height: 100%;
    user-select: none;
  }
  &__sections {
    @include flexrow;
    justify-content: flex-end;
    width: calc(100%);
    height: 100%;
    padding: 0;
  }
  &__content {
    height: 100%;
    width: 376px;
    padding: 0 0 0 12px;
    overflow: auto;
    &__main {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__section {
      max-height: calc(100% - 40px);
      overflow: auto;
      &__heading {
        @include flexbetween;
        align-items: center;
        &__left {
          @include flexAcenter;
          padding: 72px 0 24px 0;
          img {
            width: 20px;
            height: 20px;
            margin: 0 8px 0 0;
          }
          h3 {
            font-size: 14px;
            font-weight: 500;
          }
        }
        &__right {
          h3 {
            font-size: 12px;
            font-weight: 500;
            color: $primary_white;
            background: $primary_black;
            padding: 6px;
            border-radius: 4px;
          }
        }
      }
      &__main {
        padding: 0 0 16px 0;
        &__div:first-child {
          margin: 0;
        }
        &__div {
          background: $bg_grey_backround;
          padding: 8px 12px 12px 12px;
          border-radius: 8px;
          border: 1px solid $primary_grey;
          margin: 16px 0 0 0;
          hr {
            width: 100%;
            margin: 4px 0;
            border-top: 1px solid $primary_grey;
          }
          .pad {
            padding: 0;
            display: flex;
            align-items: center;
          }
          &__tab:last-child {
            padding: 0;
          }
          .tab1 {
            align-items: center;
          }
          &__tab {
            @include flexrow;
            padding: 0 0 12px 0;
            &__heading {
              @include flexrow;
              width: 104px;
              .tags {
                padding: 8px 0 0 0;
              }
              h5 {
                font-size: 12px;
                color: $grey_input;
                font-weight: 100;
                font-family: $inter_light;
              }
            }
            .heading1 {
              width: 112px;
            }
            &__content {
              width: 254px;
              text-align: right;
              @include flexrow;
              justify-content: flex-end;
              overflow: auto;
              .tab {
                p {
                font-size: 12px;
                background: $secondary_grey;
                padding: 4px 8px;
                border-radius: 6px;
                border: 1px solid $primary_grey;
                height: max-content;
                }
              }
              &__sec {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                span {
                  font-size: 12px;
                  background: $secondary_grey;
                  padding: 4px 8px;
                  border-radius: 6px;
                  margin: 4px 0 0 4px;
                  border: 1px solid $primary_grey;
                  height: max-content;
                }
                .span1 {
                  margin: 0;
                }
                h6 {
                  font-size: 12px;
                  font-weight: 400;
                }
                p {
                  font-size: 10px;
                  font-weight: 400;
                }
              }
              .text {
                max-height: 120px;
                overflow: scroll;
              }
            }
            &__heading1 {
              @include flexrow;
              width: 150px;
              .tags {
                padding: 8px 0 0 0;
              }
              h5 {
                font-size: 12px;
                color: $grey_input;
                font-weight: 100;
                font-family: $inter_light;
              }
            }
            &__content1 {
              width: 200px;
              text-align: right;
              @include flexrow;
              justify-content: flex-end;
              overflow: auto;
              &__sec {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                span {
                  font-size: 10px;
                  background: $secondary_grey;
                  padding: 4px 8px;
                  border-radius: 6px;
                  margin: 4px 0 0 4px;
                  border: 1px solid $primary_grey;
                  height: max-content;
                }
                h6 {
                  font-size: 12px;
                  font-weight: 400;
                }
                p {
                  font-size: 10px;
                  font-weight: 400;
                }
              }
              .text {
                max-height: 120px;
                overflow: scroll;
              }
            }
          }
        }
      }
      &__ref {
        &__div {
          cursor: pointer;
          width: 114px;
          background: $bg_grey_backround;
          border-radius: 8px;
          border: 1px solid $primary_grey;
          &__main {
            padding: 12px 12px 10px 12px;
            h6 {
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;
              color: #a8adaf;
              padding: 0 0 4px 0;
            }
            img {
              width: 90px;
              border-radius: 8px;
            }
          }
          :hover {
            img {
              opacity: 0.6;
            }
          }
        }
      }
    }
    &__bottom {
      &__container {
        @include flexbetween;
        align-items: flex-end;
        &__left {
          &__team {
            h4 {
              font-size: 14px;
              font-weight: 400;
              padding: 0 0 2px 0;
            }
            h5 {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        &__right {
          &__user {
            @include flexrow;
            &__content {
              text-align: end;
              padding: 0 6px 0 0;
              h4 {
                font-size: 12px;
                font-weight: 550;
              }
              p {
                font-size: 8px;
                color: $grey_input;
              }
            }
            &__icon {
              img {
                width: 24px;
              }
            }
          }
          &__mode {
            @include flexAcenter;
            justify-content: flex-end;
            img {
              width: 16px;
              height: 16px;
            }
            h4 {
              font-size: 12px;
              font-weight: 400;
              padding: 0 0 0 8px;
            }
          }
        }
      }
    }
  }
}
.custom-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.slider-image {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__icons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: none;
      max-height: none;
      background-color: lightgray;
      border-radius: 50%;
      position: absolute;
      bottom: 20px;
      width: 36px;
      height: 36px !important;
      cursor: pointer;
    }
    .two {
      display: none;
    }
    .tooltip-show {
      display: none;
      position: relative;
      justify-content: center;
    }
  }
  &__icons:hover {
    .one {
      display: none;
    }
    .two {
      display: block;
    }
    .tooltip-show {
      display: flex;
    }
  }
}
.bottom-sec {
  @include flexJCenter;
  width: 100%;
  margin: 12px 0 0 0;
}
.carousel {
  @include flexJCenter;
  margin: 0 0 0 40px;
  &__container {
    width: 340px;
    height: 64px;
    @include flexJCenter;
  }
  &__card {
    position: relative;
    width: 82px;
    margin: 0 4px 0 0;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .shadow {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0, 50%);
    }
  }
  &__card:last-child {
    margin: 0;
  }
  &__card.active {
    width: 76px;
    background: $primary_black;
    padding: 3px;
    img {
      border: 2px solid $primary_white;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }
  }
  &__card:hover {
    .shadow {
      display: block;
    }
    width: 82px;
    background: transparent;
    padding: 0;
  }
}
.gen-change {
  margin: 0 0 0 10px;
  height: 64px;
  @include flexcolumn;
  justify-content: space-between;
  &__up-arrow {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #f8f8f9;
    border: 1px solid #ededed;
    @include flexCenter;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
    .default {
      transform: rotate(-180deg);
    }
    .active {
      transform: rotate(-90deg);
      display: none;
    }
  }
  &__down-arrow {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #f8f8f9;
    border: 1px solid #ededed;
    @include flexCenter;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
    .active {
      transform: rotate(-90deg);
      display: none;
    }
  }
  &__up-arrow:hover {
    background: #d4d6d7;
  }
  &__down-arrow:hover {
    background: #d4d6d7;
  }
  &__up-arrow:active {
    background: $primary_blue;
    .active {
      display: block;
    }
    .default {
      display: none;
    }
  }
  &__down-arrow:active {
    background: $primary_blue;
    .active {
      display: block;
    }
    .default {
      display: none;
    }
  }
  &__up-arrow.disabled {
    &:hover {
      background: #f8f8f9;
    }
    img {
      opacity: 0.4;
    }
    cursor: not-allowed;
  }
  &__down-arrow.disabled {
    &:hover {
      background: #f8f8f9;
    }
    cursor: not-allowed;
    img {
      opacity: 0.4;
    }
  }
}
.prev-div {
  position: absolute;
  background: #000000b5;
  width: 44px;
  height: 44px;
  @include flexCenter;
  border-radius: 50%;
  left: 12px;
  cursor: pointer;
  z-index: 1;
}
.prev {
  position: absolute;
  width: 20px;
  height: auto;
  left: 12px;
  cursor: pointer;
}
.next-div {
  position: absolute;
  background: #000000b5;
  width: 44px;
  height: 44px;
  @include flexCenter;
  border-radius: 50%;
  right: 12px;
  cursor: pointer;
  z-index: 1;
}
.next {
  position: absolute;
  width: 20px;
  height: auto;
  right: 12px;
  cursor: pointer;
}
.underline {
  display: flex;
  justify-content: center;
  padding: $font_verysmall 0;
}
.underline-item {
  margin: 0 $font_xverysmall;
  width: $font_xlarge !important;
  height: 5px !important;
  border-radius: 1vw !important;
  left: 10px !important;
  background: $primary_grey !important;
}
.underline-item.active {
  background: $primary_black !important;
  width: $font_top9 !important;
}
.underline-item.single {
  display: none;
}

.__rcs-handle-button {
  background-image: url(../../../assets/images/icons/slidr-icon.png);
  width: 36px !important;
  height: 36px !important;
  background-size: cover !important;
  border-width: 0 !important;
}
.__rcs-handle-arrow {
  display: none !important;
}

.fav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexCenter;
  background: rgba(0, 0, 0, 0.7098039216);
}

.click-load {
  width: 100%;
  height: 100%;
  @include flexCenter;
  position: absolute;
  top: 0;
  left: 0;
  background: #00000047;
  &__container {
    @include flexCenter;
    width: 100%;
    height: 100%;
    img {
      width: 120px;
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .feed {
    &__popup {
      padding: 48px 12px 12px 12px;
      &__container {
        padding: 10px;
      }
      &__section {
        &__heading {
          top: -10px;
          right: -55px;
        }
      }
    }
  }
  .custom-slider {
    &__container {
      height: calc(100% - 62px);
    }
    &__content {
      &__section {
        &__heading {
          &__left {
            padding: 24px 0 24px 0;
            h3 {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .bottom-sec {
    margin: 10px 0 0 0;
  }
  .carousel {
    margin: 0 0 0 36px;
    &__container {
      width: 270px;
      height: 52px;
    }
    &__card {
      width: 66px;
    }
    &__card.active {
      width: 60px;
    }
  }
  .gen-change {
    height: 52px;
    &__up-arrow {
      width: 22px;
      height: 22px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    &__down-arrow {
      width: 22px;
      height: 22px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
  .underline {
    padding: 16px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .feed-popover {
    width: 136px;
    .ant-popover-inner-content {
      font-size: 10px;
    }
  }
  .feed-popover.ch {
    width: 87px;
  }
  .feed {
    &__popup {
      overflow: auto;
      &__main {
        width: 100%;
        @include flexAcenter;
      }
      &__container {
        width: calc(100% - 33px);
        height: 100%;
        padding: 8px;
      }
      &__section {
        &__heading {
          top: -7px;
          right: -43px;
          &__icons {
            padding: 16px 8px;
            .upscale-icon {
              margin: 0 0 12px 0;
            }
            .download-icon {
              margin: 0 0 12px 0;
            }
            .tooltip-top {
              .small-icon {
                width: 14px;
                height: 14px;
              }
              img {
                width: 18px;
                height: 18px;
              }
            }
            .delete-icon {
              img {
                width: 18px !important;
                height: 18px !important;
              }
            }
          }
          &__close {
            width: 18px;
            height: 18px;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
  .custom-slider {
    &__container {
      height: calc(100% - 62px);
    }
    &__main {
      width: calc(100% - 210px);
      @include flexcolumn;
      justify-content: center;
    }
    &__content {
      width: 195px;
      overflow: auto;
      &__section {
        &__heading {
          &__left {
            padding: 12px 0 12px 0;
            img {
              width: 16px;
              height: 16px;
            }
            h3 {
              font-size: 12px;
            }
          }
          &__right {
            padding: 12px 0 12px 0;
            img {
              width: 16px;
              height: 16px;
            }
            h3 {
              font-size: 12px;
              padding: 2px 4px;
            }
          }
        }
        &__main {
          &__div {
            padding: 8px;
            &__tab {
              flex-direction: column;
              padding: 0 0 8px 0;
              &__heading {
                width: 100%;
                .tags {
                  padding: 0;
                }
                h5 {
                  font-size: 11px;
                }
              }
              &__content {
                width: 100%;
                justify-content: flex-start;
                &__sec {
                  justify-content: flex-start;
                  h6 {
                    font-size: 11px;
                    text-align: start;
                  }
                  span {
                    margin: 4px 4px 0 0;
                  }
                  p {
                    text-align: start;
                  }
                }
              }
              &__heading1 {
                width: 100%;
                .tags {
                  padding: 0;
                }
                h5 {
                  font-size: 11px;
                }
              }
              &__content1 {
                width: 100%;
                justify-content: flex-start;
                &__sec {
                  justify-content: flex-start;
                  h6 {
                    font-size: 11px;
                  }
                  span {
                    margin: 4px 4px 0 0;
                  }
                }
              }
            }
          }
        }
        &__ref {
          &__div {
            width: 80px;
            &__main {
              padding: 10px 10px 8px 10px;
              h6 {
                font-size: 10px;
              }
              img {
                width: 60px;
              }
            }
          }
        }
      }
      &__bottom {
        &__container {
          flex-direction: column;
          align-items: flex-start;
          &__left {
            &__team {
              h4 {
                font-size: 12px;
              }
              h5 {
                font-size: 12px;
              }
            }
          }
          &__right {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            &__user {
              flex-direction: row-reverse;
              padding: 6px 0 0 0;
              &__content {
                text-align: start;
                padding: 0 0 0 6px;
                h4 {
                  font-size: 12px;
                }
                p {
                  font-size: 10px;
                }
              }
              &__icon {
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
            &__mode {
              width: 100%;
              padding-top: 6px;
              h4 {
                font-size: 10px;
              }
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
  .prev-div {
    width: 24px;
    height: 24px;
  }
  .prev {
    width: 16px;
    left: 4px;
  }
  .next-div {
    width: 24px;
    height: 24px;
  }
  .next {
    width: 16px;
    right: 3px;
  }
  .__rcs-handle-button {
    width: 24px !important;
    height: 24px !important;
  }
  .underline {
    padding: 4px 0 0 0;
  }
  .underline-item.active {
    width: 32px !important;
    height: 4px !important;
  }
  .underline-item {
    width: 14px !important;
    height: 4px !important;
  }
  .bottom-sec {
    margin: 10px 0 0 0;
  }
  .carousel {
    margin: 0 0 0 36px;
    &__container {
      width: 270px;
      height: 52px;
    }
    &__card {
      width: 66px;
    }
    &__card.active {
      width: 60px;
    }
  }
  .gen-change {
    height: 52px;
    &__up-arrow {
      width: 20px;
      height: 20px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    &__down-arrow {
      width: 20px;
      height: 20px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .feed-popover {
    width: 136px;
    .ant-popover-inner-content {
      font-size: 10px;
    }
  }
  .feed-popover.ch {
    width: 87px;
  }
  .feed {
    &__popup {
      padding: 36px 12px 12px 12px;
      &__main {
        width: 100%;
        @include flexAcenter;
      }
      &__container {
        width: calc(100% - 33px);
        max-height: 100%;
        padding: 12px;
      }
      &__section {
        &__heading {
          top: -7px;
          right: -45px;
          &__icons {
            padding: 16px 8px;
            .upscale-icon {
              margin: 0 0 12px 0;
            }
            .download-icon {
              margin: 0 0 12px 0;
            }
            .tooltip-top {
              .small-icon {
                width: 14px;
                height: 14px;
              }
              img {
                width: 18px;
                height: 18px;
              }
            }
            .delete-icon {
              img {
                width: 18px !important;
                height: 18px !important;
              }
            }
          }
          &__close {
            width: 20px;
            height: 20px;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
  .custom-slider {
    &__container {
      height: calc(100% - 62px);
    }
    &__main {
      width: 70%;
      @include flexcolumn;
      justify-content: center;
    }
    &__content {
      width: calc(30% - 8px);
      overflow: auto;
      padding: 0 0 0 8px;
      &__section {
        &__heading {
          &__left {
            padding: 12px 0 12px 0;
            img {
              width: 16px;
              height: 16px;
            }
            h3 {
              font-size: 12px;
            }
          }
          &__right {
            padding: 12px 0 12px 0;
            img {
              width: 16px;
              height: 16px;
            }
            h3 {
              font-size: 12px;
            }
          }
        }
        &__main {
          &__div {
            padding: 8px;
            border-radius: 4px;
            &__tab {
              flex-direction: column;
              padding: 0 0 8px 0;
              &__heading {
                width: 100%;
                .tags {
                  padding: 0;
                }
                h5 {
                  font-size: 11px;
                }
              }
              &__content {
                width: 100%;
                justify-content: flex-start;
                .text {
                  justify-content: flex-start;
                }
                &__sec {
                  justify-content: flex-start;
                  h6 {
                    font-size: 11px;
                    text-align: start;
                  }
                  p {
                    text-align: start;
                  }
                  span {
                    margin: 4px 4px 0 0;
                  }
                }
              }
              &__heading1 {
                width: 100%;
                .tags {
                  padding: 0;
                }
                h5 {
                  font-size: 11px;
                }
              }
              &__content1 {
                width: 100%;
                justify-content: flex-start;
                &__sec {
                  justify-content: flex-start;
                  h6 {
                    font-size: 11px;
                  }
                  span {
                    margin: 4px 4px 0 0;
                  }
                }
              }
            }
          }
        }
        &__ref {
          &__div {
            width: 80px;
            &__main {
              padding: 8px 8px 6px 8px;
              h6 {
                font-size: 10px;
              }
              img {
                width: 60px;
              }
            }
          }
        }
      }
      &__bottom {
        &__container {
          flex-direction: column;
          align-items: flex-start;
          &__left {
           &__team {
            h4 {
              font-size: 12px;
            }
            h5 {
              font-size: 12px;
            }
           }
          }
          &__right {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            &__user {
              flex-direction: row-reverse;
              padding: 6px 0 0 0;
              &__content {
                text-align: start;
                padding: 0 0 0 6px;
                h4 {
                  font-size: 12px;
                }
                p {
                  font-size: 10px;
                }
              }
              &__icon {
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
            &__mode {
              width: 100%;
              padding-top: 6px;
              h4 {
                font-size: 10px;
              }
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
  .prev-div {
    width: 24px;
    height: 24px;
  }
  .prev {
    width: 16px;
    left: 4px;
  }
  .next-div {
    width: 24px;
    height: 24px;
  }
  .next {
    width: 16px;
    right: 3px;
  }
  .__rcs-handle-button {
    width: 24px !important;
    height: 24px !important;
  }
  .underline {
    padding: 4px 0 0 0;
  }
  .underline-item.active {
    width: 32px !important;
    height: 4px !important;
  }
  .underline-item {
    width: 14px !important;
    height: 4px !important;
  }
  .bottom-sec {
    margin: 10px 0 0 0;
  }
  .carousel {
    margin: 0 0 0 36px;
    &__container {
      width: 270px;
      height: 52px;
    }
    &__card {
      width: 66px;
    }
    &__card.active {
      width: 60px;
    }
  }
  .gen-change {
    height: 52px;
    &__up-arrow {
      width: 20px;
      height: 20px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    &__down-arrow {
      width: 20px;
      height: 20px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
