@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.header-popover-language {
  width: 240px !important;
}
.language {
  height: 40px;
  @include flexAcenter;
  padding: 0 0 0 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: $secondary_grey;
  }
  &.active {
    background: $primary_blue;
    color: $primary_white;
  }
  &.disable {
    color: #d4d6d7;
    cursor: default;
    &:hover {
      background: none;
    }
  }
  &__main {
    @include flexcolumn;
    gap: 4px;
  }
  &__location {
    height: 24px;
    @include flexAcenter;
    padding: 0 0 0 16px;
    margin: 0 0 12px 0;
    font-size: 12px;
    font-weight: 400;
  }
}

.header-language-popover {
  width: 144px !important;
  .ant-popover-inner {
    padding: 6px !important;
  }
}

.faq-header-popover {
  width: 200px !important;
}
.faq-header {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 0 6px 8px;
  cursor: pointer;
  &.active {
    background: $primary_blue;
    color: $primary_white;
  }
}

.header-popover {
  .ant-popover-inner-content {
    padding: 0 8px !important;
  }
}

.header {
  font-family: $inter_regular !important;
  background: $primary_white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  height: 48px;
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #ededed;
    width: 100%;
    height: 100%;
  }
  &__nav {
    width: 100%;
    height: 100%;
    @include flexbetween;
    align-items: center;
    &__logo {
      height: 100%;
      @include flexbetween;
      align-items: center;
      .logo {
        height: 100%;
        width: 232px;
        pointer-events: none;
        img {
          width: auto;
          height: 100%;
          pointer-events: auto;
          cursor: pointer;
          aspect-ratio: initial;
        }
        .logoch {
          width: 174px;
        }
      }
    }
    &__links {
      @include flexAcenter;
      gap: 16px;
      a {
        @include flexCenter;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        img {
          width: 32px;
          height: 32px;
        }
        .active {
          display: none;
        }
        &:hover {
          background: $primary_grey;
        }
        &.active {
          background: $primary_blue;
          .active {
            display: block;
          }
          .default {
            display: none;
          }
        }
      }
    }
    &__link {
      @include flexCenter;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      img {
        width: 32px;
        height: 32px;
      }
      .active {
        display: none;
      }
      &:hover {
        background: $primary_grey;
      }
      &.active {
        background: $primary_blue;
        .active {
          display: block;
        }
        .default {
          display: none;
        }
      }
    }
    &__buttons {
      @include flexbetween;
      align-items: center;
      margin: 0 12px 0 0;
      &__language {
        background: none;
        height: 32px;
        border: 1px solid $primary_grey;
        border-radius: 18px;
        padding: 0 12px 0 8px;
        @include flexAcenter;
        cursor: pointer;
        margin: 0 12px 0 0;
        img {
          width: 28px;
          height: 28px;
          display: none;
        }
        .globed {
          display: block;
        }
        h5 {
          font-size: 16px;
          padding: 0 0 2px 8px;
          height: 19px;
          font-weight: 500;
          @include flexAcenter;
        }
        &:hover {
          background: $secondary_grey;
        }
        &:active {
          // background: $primary_blue;
          border: none;
          .globea {
            display: block;
          }
          .globed {
            display: none;
          }
          h5 {
            color: $primary_white;
          }
        }
      }
      &__sign-in {
        width: 72px;
        font-size: 16px;
        height: 32px;
        padding: 0;
        border-radius: 42px;
        border: 1px solid $primary_grey;
        background: $primary_blue;
        color: $primary_white;
        cursor: pointer;
        &:disabled {
          cursor: default;
          background: $secondary_grey;
        }
      }
    }
    &__workspace {
      height: 24px;
      @include flexAcenter;
      gap: 24px;
      &__name {
        @include flexAcenter;
      }
      &__team-name {
        padding: 0 8px 0 0;
        margin: 0 8px 0 0;
        border-right: 1px solid $primary_blue;
        h5 {
          a {
            color: $primary_blue;
            font-size: 16px;
            font-weight: 500;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            &:hover {
              color: $primary_blue;
            }
          }
        }
      }
      &__project-name {
        h5 {
          font-size: 16px;
          font-weight: 500;
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &__link {
        a {
          @include flexAcenter;
          border-radius: 6px;
          img {
            width: 32px;
            height: 32px;
            &.active {
              display: none;
            }
          }
          &:hover {
            background: $primary_grey;
          }
          &.active {
            background: $primary_blue;
            img {
              &.active {
                display: block;
              }
              &.default {
                display: none;
              }
            }
          }
        }
      }
    }
    &__user {
      margin: 0 12px 0 0;
      @include flexrowacenter;
      gap: 12px;

      &__team {
        @include flexAcenter;
        gap: 8px;

        .upgrade-button {
          @include flexrowacenter;
          width: 144px;
          height: 34px;
          border: 1px solid $primary_grey;
          padding: 0 20px;
          border-radius: 18px;
          background: linear-gradient(
            90deg,
            rgba(15, 32, 39, 1) 0%,
            rgba(32, 58, 67, 1) 35%,
            rgba(44, 83, 100, 1) 100%
          );

          // &.deal-active {
          //   background: linear-gradient(90deg, #3dbdb6 0%, #2c5364 100%);
          // }

          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
          span {
            font-size: 16px;
            font-family: $inter_semibold;
            color: $primary_white;
            padding: 0 0 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
          }
        }

        &__notification {
          @include flexrowacenter;
          border-radius: 6px;
          position: relative;
          .red-dot {
            width: 6px;
            height: 6px;
            background: #ff4d4f;
            position: absolute;
            top: 4px;
            right: 4px;
            border-radius: 50%;
            animation: blinkBorder 1.2s infinite;
          }
          img {
            width: 32px;
            height: 32px;
            cursor: pointer;
            &.active {
              display: none;
            }
          }
          &:hover {
            background: $primary_grey;
          }
          &.active {
            background: $primary_blue;
            img {
              &.active {
                display: block;
              }
              &.default {
                display: none;
              }
            }
          }
          @keyframes blinkBorder {
            0%,
            100% {
              box-shadow: 0 0 0 2px transparent;
            }
            50% {
              box-shadow: 0 0 0 4px #ff4d5054;
            }
          }
        }
      }

      &__language {
        @include flexrowacenter;
        border-radius: 6px;
        img {
          width: 32px;
          height: 32px;
          cursor: pointer;
          &.active {
            display: none;
          }
        }
        &:hover {
          background: $primary_grey;
        }
        &.active {
          // background: $primary_blue;
          img {
            &.active {
              display: block;
            }
            &.default {
              display: none;
            }
          }
        }
      }

      &__icon {
        @include flexcolumn;
        cursor: pointer;
        .usericon {
          width: 32px;
          height: 32px;
        }
        &.active {
          .menu {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            background: transparent;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            cursor: default;
          }
        }
      }

      &__menu {
        display: none;
        background: $primary_white;
        z-index: 1000;
        &__links {
          @include flexcolumn;
          padding: 12px 0;
          width: 200px;
          height: max-content;
          background: $primary_white;
          border: 1px solid $primary_grey;
          z-index: 1000;
          margin: 60px 12px 0 0;
          border-radius: 8px;
          max-height: calc(100vh - 98px);
          overflow: auto;
        }
        &__link {
          @include flexAcenter;
          cursor: pointer;
          margin: 0 0 4px 0;
          .log-out {
            width: 24px !important;
            height: 24px !important;
            padding: 0;
          }
          a {
            width: 100%;
            height: 40px;
            @include flexAcenter;
            text-decoration: none;
            color: $primary_black;
            padding: 0 12px 0 16px;
            img {
              width: 22px;
              height: 22px;
              &.active {
                display: none;
              }
            }
            span {
              padding: 0 0 0 12px;
              font-size: 14px;
              font-family: $inter_semibold;
            }
            &.active {
              background: $primary_black;
              span {
                color: $primary_white;
              }
              .default {
                display: none;
              }
              .active {
                display: block;
              }
            }
            &:hover {
              opacity: 0.6;
              span {
                color: $primary_black;
              }
            }
          }
          &__main {
            width: 100%;
            padding: 0 12px 0 16px;
            &__heading {
              height: 40px;
              @include flexrowacenter;
              .langb {
                width: 20px !important;
                height: 20px !important;
                padding: 0 2px;
              }
              span {
                font-size: 14px;
                font-family: "Inter SemiBold";
                padding: 0 12px;
              }
              img {
                width: 18px !important;
                height: 18px !important;
              }
            }
            &__heading:hover {
              opacity: 0.6;
            }
            &__heading.active {
              opacity: 1;
              .arrow {
                transform: rotate(-90deg);
              }
            }
            &__dropdown {
              display: none;
              margin: 0 0 0 34px;
              .lang-select {
                @include flexrowacenter;
                opacity: 0.4;
                padding: 6px 0 6px 0;
                span {
                  font-size: 14px;
                  font-weight: 400;
                  padding: 0 0 0 22px;
                }
                img {
                  display: none;
                  width: 12px !important;
                  height: 12px !important;
                }
              }
              .lang-select:hover {
                opacity: 0.6;
              }
              .lang-select.active {
                opacity: 1;
                img {
                  display: block;
                }
                span {
                  padding: 0 0 0 10px;
                }
              }
            }
            &__dropdown.active {
              @include flexcolumn;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1090px) {
  .header {
    height: 36px;
    &__nav {
      &__logo {
        .logo {
          width: 144px;
        }
      }
      &__links {
        gap: 8px;
        a {
          width: 20px;
          height: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      &__link {
        width: 20px;
        height: 20px;
      }
      &__workspace {
        gap: 12px;
        &__name {
          h5 {
            padding: 0 6px 0 0;
            max-width: 84px;
            font-size: 12px;
            a {
              max-width: 96px;
              font-size: 12px;
            }
          }
        }
        &__link {
          a {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      &__user {
        gap: 8px;
        margin: 0 8px 0 0;
        &__team {
          .upgrade-button {
            height: 24px;
            width: 24px;
            padding: 0 5px;
            border-radius: 16px;
            img {
              width: 12px;
              height: 12px;
              vertical-align: unset;
            }
            span {
              display: none;
              vertical-align: unset;
            }
          }
          &__notification {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        &__language {
          img {
            width: 20px;
            height: 20px;
          }
        }
        &__icon {
          .usericon {
            width: 24px;
            height: 24px;
          }
        }
        &__menu {
          &__links {
            width: 160px;
            margin: 48px 12px 0 0;
            padding: 8px 0;
          }
          &__link {
            a {
              height: 32px;
              padding: 0 8px 0 12px;
              img {
                width: 16px;
                height: 16px;
              }
              span {
                font-size: 12px;
                padding: 0 0 0 8px;
              }
            }
            &__main {
              padding: 0 8px 0 12px;
              &__heading {
                height: 32px;
                .langb {
                  width: 14px !important;
                  height: 14px !important;
                }
                span {
                  font-size: 12px;
                  padding: 0 8px;
                }
              }
              &__dropdown {
                margin: 0 0 0 28px;
                .lang-select {
                  padding: 4px 0;
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      &__buttons {
        &__language {
          height: 28px;
          img {
            width: 20px;
            height: 20px;
          }
          h5 {
            font-size: 14px;
          }
        }
        &__sign-in {
          height: 28px;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .header {
    &__nav {
      &__workspace {
        &__name {
          h5 {
            max-width: 140px;
          }
        }
      }
    }
  }
}
