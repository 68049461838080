@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.custom-popover1 {
  width: 140px !important;
}
.ant-popover-inner {
  padding: 8px 0 !important;
}

.workshop-teams {
  height: calc(var(--vh, 1vh) * 100 - 344px);
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .cropper-modal{
    height: 450px;
    border-radius: 10px;
    opacity: 1;
    align-items: center;
    &__main {
      justify-content: center;
    }
  }
  &__container {
    height: 100%;
  }
  &__section {
    height: 100%;
    &__teams {
      border: 1px solid $primary_grey;
      background: $primary_white;
      border-radius: 8px;
      margin: 16px 0;
      overflow: hidden;
      height: 100%;
      &__heading {
        height: 52px;
        padding: 0 20px;
        border-bottom: 1px solid $primary_grey;
        @include flexrowacenter;
        h2 {
          font-size: 18px;
          font-family: $inter_semibold;
        }
      }
      &__details {
        @include flexbetween;
        height: calc(100% - 52px);
        &__list {
          width: 290px;
          @include flexcolumn;
          justify-content: space-between;
          margin: 8px 0;
          padding: 12px 14px 12px 0;
          border-right: 1px solid $primary_grey;
          &__main {
            padding: 0;
            height: calc(100% - 44px);
          }
          &__all {
            margin: 0 20px;
            padding: 0 12px;
            @include flexrowacenter;
            height: 33px;
            cursor: pointer;
            img {
              width: 14px;
              height: 14px;
            }
            h4 {
              font-size: 14px;
              font-family: $inter_regular;
              padding: 0 0 0 2px;
            }
            .default-arrow {
              display: block;
            }
            .active-arrow {
              display: none;
            }
          }
          &__all.active {
            background: $primary_blue;
            &:hover {
              background: $secondary_grey;
              h4 {
                color: $primary_blue;
              }
              .default-arrow {
                display: block;
              }
              .active-arrow {
                display: none;
              }
            }
            .default-arrow {
              display: none;
            }
            .active-arrow {
              display: block;
            }
            h4 {
              color: $primary_white;
            }
          }
          &__search {
            margin: 16px 20px;
            border-top: 1px solid $secondary_grey;
            padding: 16px 0 0 0;
            position: relative;
            @include flexAcenter;
            img {
              position: absolute;
              left: 12px;
              width: 20px;
              height: 20px;
            }
            input {
              width: 100%;
              height: 40px;
              padding: 0 25px 0 40px;
              border-radius: 8px;
              border: 1px solid $primary_grey;
              font-size: 14px;
              outline: none;
            }
          }
          &__data {
            margin: 0 20px;
            height: calc(100% - 124px);
            overflow: auto;
            &__team {
              @include flexrowacenter;
              justify-content: space-between;
              height: 33px;
              padding: 0 12px;
              cursor: pointer;
              margin: 0 0 4px 0;
              &:last-child {
                margin: 0;
              }
              &:hover {
                background: $secondary_grey;
                .card-dots {
                  display: block;
                }
              }
              &__left {
                @include flexrowacenter;
                max-width: 200px;
              }
              &__icon {
                @include flexrowacenter;
                img {
                  width: 14px;
                  height: 14px;
                }
                .active-arrow {
                  display: none;
                }
              }
              &__content {
                @include flexrowacenter;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                img {
                  width: 14px;
                  height: 14px;
                  margin: 0 4px;
                }
                .active-icon {
                  display: none;
                }
                h4 {
                  font-size: 14px;
                  font-family: $inter_regular;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              &__right {
                width: 20px;
                height: 20px;
                img {
                  width: 20px;
                  height: 20px;
                  display: none;
                }
                &__sec {
                  padding: 4px 10px;
                  @include flexAcenter;
                  cursor: pointer;
                  img {
                    width: 16px;
                    height: 16px;
                    opacity: 0.4;
                  }
                  h5 {
                    font-size: 12px;
                    font-family: $inter_regular;
                    padding: 0 0 0 8px;
                    color: $font_grey;
                  }
                }
                &__sec:hover {
                  background: $secondary_grey;
                  img {
                    opacity: 0.6;
                  }
                }
                &__sec:active {
                  background: $secondary_grey;
                  img {
                    opacity: 1;
                  }
                }
              }
            }
            &__team.active {
              background: $primary_blue;
              &:hover {
                background: $secondary_grey;
                h4 {
                  color: $primary_blue;
                }
                .default-arrow {
                  display: block;
                }
                .active-arrow {
                  display: none;
                }
                .default-icon {
                  display: block;
                }
                .active-icon {
                  display: none;
                }
              }
              .default-arrow {
                display: none;
              }
              .active-arrow {
                display: block;
              }
              .default-icon {
                display: none;
              }
              .active-icon {
                display: block;
              }
              h4 {
                color: $primary_white;
              }
            }
            &__team.hovered {
              background: $secondary_grey;
              .card-dots {
                display: block;
              }
            }
            &__loader {
              padding: 40px 0 0 0;
              @include flexJCenter;
              width: 100%;
              img {
                width: 60px;
              }
            }
          }
          &__button {
            margin: 0 20px;
            button {
              width: 100%;
              border-radius: 16px;
              border: 1px solid $primary_blue;
              background: none;
              height: 33px;
              cursor: pointer;
            }
          }
        }
        &__section {
          width: 934px;
          height: 100%;
          &__main {
            padding: 20px;
            height: calc(100% - 40px);
          }
          &__tabs {
            width: 180px;
            height: 41px;
            border: 1px solid $primary_grey;
            border-radius: 23px;
            @include flexrowacenter;
            justify-content: space-between;
            background: $bg_grey_backround;
          }
          &__tab {
            border-radius: 23px;
            height: 35px;
            @include flexrowacenter;
            padding: 0 12px;
            margin: 0 4px;
            cursor: pointer;
            h4 {
              font-size: 16px;
              color: $primary_blue;
            }
          }
          &__tab.active {
            background: $primary_blue;
            h4 {
              color: $primary_white;
            }
          }
          &__content {
            padding: 36px 0 0 0;
            height: calc(100% - 80px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1610px) {
  .workshop-teams {
    &__section {
      &__teams {
        &__heading {
          h2 {
            font-size: 16px;
          }
        }
        &__details {
          &__list {
            width: 202px;
            padding: 8px 10px 8px 0;
            &__all {
              margin: 0 12px;
              h4 {
                font-size: 12px;
              }
            }
            &__search {
              margin: 12px;
              padding: 12px 0 0 0;
              input {
                height: 36px;
              }
            }
            &__data {
              margin: 0 12px;
              height: calc(100% - 110px);
              &__team {
                height: 31px;
                padding: 0 8px;
                &__content {
                  h4 {
                    font-size: 12px;
                    width: 104px;
                  }
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
            }
          }
          &__section {
            width: 652px;
            &__main {
              padding: 12px;
              height: calc(100% - 25px);
            }
            &__tabs {
              width: 158px;
              height: 32px;
            }
            &__tab {
              height: 28px;
              h4 {
                font-size: 14px;
              }
            }
            &__content {
              padding: 24px 0 0 0;
              height: calc(100% - 46px);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1090px) and (max-width: 1250px) {
  .workshop-teams {
    &__section {
      &__teams {
        &__heading {
          h2 {
            font-size: 14px;
          }
        }
        &__details {
          &__list {
            width: 202px;
            padding: 8px 10px 8px 0;
            &__all {
              margin: 0 12px;
              h4 {
                font-size: 12px;
              }
            }
            &__search {
              margin: 12px;
              padding: 12px 0 0 0;
              input {
                height: 36px;
              }
            }
            &__data {
              margin: 0 12px;
              height: calc(100% - 110px);
              &__team {
                height: 31px;
                padding: 0 8px;
                &__content {
                  h4 {
                    font-size: 12px;
                    width: 104px;
                  }
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
            }
          }
          &__section {
            width: 518px;
            &__main {
              padding: 12px;
              height: calc(100% - 25px);
            }
            &__tabs {
              width: 158px;
              height: 32px;
            }
            &__tab {
              height: 28px;
              h4 {
                font-size: 14px;
              }
            }
            &__content {
              padding: 24px 0 0 0;
              height: calc(100% - 46px);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1090px) {
  .workshop-teams {
    height: auto;
    &__section {
      &__teams {
        &__heading {
          h2 {
            font-size: 14px;
          }
        }
        &__details {
          &__list {
            width: 202px;
            padding: 8px 10px 8px 0;
            &__all {
              margin: 0 12px;
              h4 {
                font-size: 12px;
              }
            }
            &__search {
              margin: 12px;
              padding: 12px 0 0 0;
              input {
                height: 36px;
              }
            }
            &__data {
              margin: 0 12px;
              height: auto;
              &__team {
                height: 31px;
                padding: 0 8px;
                &__content {
                  h4 {
                    font-size: 12px;
                    width: 104px;
                  }
                  img {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
            }
          }
          &__section {
            width: 444px;
            &__main {
              padding: 12px;
              height: calc(100% - 25px);
            }
            &__tabs {
              width: 158px;
              height: 32px;
            }
            &__tab {
              height: 28px;
              h4 {
                font-size: 14px;
              }
            }
            &__content {
              padding: 24px 0 0 0;
              height: calc(100% - 46px);
            }
          }
        }
      }
    }
  }
}
